<template>
  <div>
    <h4 class="font-weight-bold">RELATED PARTIES</h4>
    <button class="mb-2 btn btn-success btn-sm" data-toggle="modal" @click="resetForm" data-target="#relatedPartyModal">
      <font-awesome-icon icon="plus" class="force-white-all"/>
      ADD PARTY
    </button>
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-12 col-xl-3" v-for="(party,index) in relatedPartyLists">
        <div class="card mb-2">
          <div>
            <div class="row align-items-center">
              <div class="col-6">
                <h6 class="card-title p-2 font-weight-bold mb-0">{{ party.party_name ? party.party_name : '' }}</h6>
              </div>
              <div class="col-6 text-right">
                <button class="btn-xs btn btn-warning mr-2" title="EDIT RELATED PARTY"
                        data-target="#relatedPartyUpdateModal"
                        @click="setForm(party)"
                        data-toggle="modal">
                  <font-awesome-icon icon="pen" class="force-white-all"/>
                </button>
                <button class="btn-xs btn btn-danger mr-2" title="DELETE RELATED PARTY"
                        @click="deleteRelatedParty(party.id)">
                  <font-awesome-icon icon="trash" class="force-white-all"/>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p class="mb-0" style="font-size: 14px;">Vessels:</p>
            <div class="mb-2 ml-2">
              <table>
                <tr v-for="vessel in party.vessels">
                  <td style="font-weight: bold">{{ vessel.vessel_name ? vessel.vessel_name : '' }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="relatedPartyModal" tabindex="-1" aria-labelledby="relatedPartyModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold" id="exampleModalLabel">Add Related Party</h5>
            <button type="button" class="close"  ref="closeRelatedPartyModal" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addRelatedParty">
              <div class="form-group">
                <label for="group" class="col-form-label">Party Name:</label>
                <input type="text" name="party" v-model="fields.party" id="group" class="form-control"
                       placeholder="Party Name">
                <div v-if="errors.party">
                  <span class="text-danger font-weight-bold">{{ errors.party[0] }}</span>
                </div>
              </div>
              <div class="form-group">
                <label for="group" class="col-form-label">Code:</label>
                <input type="text" name="code" v-model="fields.code" id="group" class="form-control" placeholder="Code">
                <div v-if="errors.code">
                  <span class="text-danger font-weight-bold">{{ errors.code[0] }}</span>
                </div>
              </div>
              <div class="text-right">
                <button type="submit" class="btn btn-success btn-sm">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="relatedPartyUpdateModal" tabindex="-1" aria-labelledby="relatedPartyUpdateModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold"  id="exampleModalLabel">Update Related Party</h5>
            <button type="button" class="close" ref="closeUpdateRelatedPartyModal" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateRelatedParty">
              <div>
                <div class="form-group">
                  <label for="group" class="col-form-label">Party Name:</label>
                  <input type="text" name="party" v-model="fields.party" id="group" class="form-control"
                         placeholder="Party Name">
                  <div v-if="errors.party">
                    <span class="text-danger font-weight-bold">{{ errors.party[0] }}</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="group" class="col-form-label">Code:</label>
                  <input type="text" name="code" v-model="fields.code" id="group" class="form-control"
                         placeholder="Code">
                  <div v-if="errors.code">
                    <span class="text-danger font-weight-bold">{{ errors.code[0] }}</span>
                  </div>
                </div>
                <div class="text-right">
                  <button type="submit" class="btn btn-success btn-sm">Save</button>
                </div>
              </div>
              <div class="row">
                <div class="col-3" v-for="vessel in vesselKeys">
                  <input type="checkbox" class="eldest-sister"
                         v-model="fields.vessels"
                         :value="vessel.id">
                  {{ vessel.name.toUpperCase() }}
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {RelatedPartyService} from "@/services/RelatedPartyService";
import {AlertService} from "@/services/AlertService";

export default {
  name: "SettingsRelatedParty",
  data() {
    return {
      errors: {},
      fields: {
        party: '',
        code: ''
      }
    }
  },
  computed: {
    ...mapGetters(['relatedPartyLists', 'vesselKeys'])
  },
  methods: {
    ...mapActions(['getRelatedPartiesList', 'getVesselKeys']),

    async addRelatedParty() {
      if (await AlertService.confirmCreateAlert('Do you want to Add New Related Party?')) {
        const response = await RelatedPartyService.addRelatedParty(this.fields);
        if (response.errors) {
          this.errors = response.errors
        } else {
          AlertService.successAlert('A New Related Party has been Added Successfully!', 'Add Related Party')
          this.$refs.closeRelatedPartyModal.click()
          await this.getRelatedPartiesList()
        }
      }
    },
    resetForm() {
      this.fields = {
        party: '',
        code: ''
      }
      this.errors = {}
    },

    setForm(relatedParty) {
      this.resetForm();
      this.fields = {
        id: relatedParty?.id ?? null,
        party: relatedParty?.party_name ?? '',
        code: relatedParty?.code ?? '',
        vessels: [...new Set (relatedParty?.vessels.map(vessel=>{
          return vessel.vessel_id
        }))]
      }
    },
    async updateRelatedParty(){
      if (await AlertService.confirmUpdateAlert('Do you want to Update Related Party ?')) {
        this.fields.vessels = this.fields.vessels.filter(vessel=> {
          return typeof vessel === 'number';
        })
        const response = await RelatedPartyService.updateRelatedParty(this.fields)
        if (response.errors) {
          this.errors = response.errors
        } else {
          AlertService.successAlert('Related Party has been Updated Successfully!', 'Update Related Party')
          this.$refs.closeUpdateRelatedPartyModal.click()
          await this.getRelatedPartiesList()
        }
      }
    },
    async deleteRelatedParty(relatedPartyId){
      if (await AlertService.confirmDeleteAlert('DELETION OF RELATED PARTY','Do you want to Delete Related Party?','Yes, Delete it!')) {
        const response = await RelatedPartyService.deleteRelatedParty({id:relatedPartyId})
        if(!response.errors) {
          AlertService.successAlert('Related Party has been Deleted Successfully!', 'Update Related Party')
          await this.getRelatedPartiesList()
        }
      }
    }

  },
  created() {
    this.getRelatedPartiesList();
    this.getVesselKeys();
  }
}
</script>

<style scoped>

</style>
